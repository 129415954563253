<style scoped lang="scss">
.title {
  font-size: $Size30;
  margin-bottom: 40px;
}

#concat {
  background-color: #fff;

  .index_banner {
    width: 100%;
    min-width: 1400px;
    min-height: 335.42px;
    position: relative;

    .banner {
      width: 100%;
    }
  }

  .companyInfo {
    padding-top: 95px;
    height: 540px;

    .companyItem {
      height: 52px;
      margin-bottom: 32px;
    }

    .icon {
      width: 52px;
    }

    .line {
      padding-left: 8px;

      .line-1 {
        font-size: $Size16;
        color: $indexColor999;
      }

      .line-2 {
        font-size: $Size18;
      }
    }

    .company-line {
      line-height: 26px;
    }

    .form {
      width: 705px;

      .eare {
        width: 705px;
        height: 206px;

        .el-textarea__inner {
        }
      }
    }
  }

  .map {
    width: 1400px;
    height: 786px;
    padding-bottom: 120px;
  }
}

.successModal {
  width: 100px;
  height: 40px;
  text-align: center;
  border-radius: 8px;
  color: white;
  line-height: 40px;
  font-size: $Size16;
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 50px);
  background-color: rgba($color: #000000, $alpha: 0.8);
}
</style>

<template>
  <div id="concat">
    <div class="index_banner">
      <img class="banner" src="@assets/images/concat/banner.jpg" alt="" />
    </div>
    <!-- 人才招聘--->
    <div class="width">
      <div class="companyInfo acea-row row-between">
        <div class="companyInfoList">
          <div class="title">Hi,我们在这里</div>
          <div class="acea-row">
            <div class="companyItem icon acea-row row-middle row-center">
              <img src="@assets/images/concat/address.png" alt="" />
            </div>
            <div class="companyItem line">
              <div class="company-line line-1">办公地址</div>
              <div class="company-line line-2">
                深圳市南山区朗山路13号紫光信息港B座3层301室
              </div>
            </div>
          </div>
          <div class="acea-row">
            <div class="companyItem icon acea-row row-middle row-center">
              <img src="@assets/images/concat/phone.png" alt="" />
            </div>
            <div class="companyItem line">
              <div class="company-line line-1">商务电话</div>
              <div class="company-line line-2">0755-8603-6686</div>
            </div>
          </div>
          <div class="acea-row">
            <div class="companyItem icon acea-row row-middle row-center">
              <img src="@assets/images/concat/job_time.png" alt="" />
            </div>
            <div class="companyItem line">
              <div class="company-line line-1">工作时间</div>
              <div class="company-line line-2">
                周一至周五，09：00-18：30（北京时间）
              </div>
            </div>
          </div>
          <div class="acea-row">
            <div class="companyItem icon acea-row row-middle row-center">
              <img src="@assets/images/concat/email.png" alt="" />
            </div>
            <div class="companyItem line">
              <div class="company-line line-1">邮箱</div>
              <div class="company-line line-2">hr@aifrutech.com</div>
            </div>
          </div>
        </div>
        <div class="form">
          <div class="title">欢迎您给我们提出问题</div>
          <el-form
            :inline="true"
            :model="formInline"
            ref="formInline"
            :rules="rules"
            class="demo-form-inline"
          >
            <el-form-item prop="user">
              <el-input
                class="inputName"
                v-model="formInline.user"
                placeholder="姓名*"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input
                class="inputEmail"
                v-model="formInline.email"
                placeholder="邮箱*"
              ></el-input>
            </el-form-item>
            <el-form-item prop="desc">
              <el-input
                class="eare noborderRadio"
                type="textarea"
                v-model="formInline.desc"
                placeholder="请输入您的友情留言，我们会在第一时间回复"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn_concat"
                type="primary"
                @click="onSubmit('formInline')"
                >提交留言</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 成功提示弹窗 -->
      <div v-if="successModal" class="successModal">提交成功!</div>
      <!-- 百度地图开始 -->
      <el-image :src="mapUrl" class="map" fit="contain" />
    </div>
  </div>
</template>

<script>
export default {
  name: "concat",
  data() {
    return {
      mapUrl: require("@assets/images/concat/map.jpg"),
      formInline: {
        user: "",
        email: "",
        desc: ""
      },
      rules: {
        user: [{ required: true, message: "请输入姓名", trigger: "change" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "change" }],
        desc: [
          { required: false, message: "请填写意见或建议", trigger: "blur" }
        ]
      },
      // 弹窗提示
      successModal: false
    };
  },
  computed: {},
  created() {},
  mounted() {
    new this.$WOW.WOW({ offset: "40" }).init();
  },
  methods: {
    onSubmit(formName) {
      let _that = this;
      _that.$refs[formName].validate(valid => {
        if (valid) {
          // alert('submit!');
          const loading = _that.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          setTimeout(() => {
            loading.close();
            _that.$refs[formName].resetFields();
            _that.successModal = true;
          }, 2000);
          setTimeout(() => {
            _that.successModal = false;
          }, 3500);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
